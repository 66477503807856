import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function NotesDialog(props) {
  const [notes, setNotes] = React.useState("");
  const [error, setError] = React.useState(false)

  const saveNotes = () => {
    setError(false)
    props.saveNotes(notes);
    setNotes("");
  };

  const handleClose = () => {
    setNotes("");
    setError(false)
    props.closeNotes(false);
  };

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        open={props.open}
        onClose={handleClose}
        fullWidth={ true }
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
        <div style={{display:error===true?'block':'none', color:'red',margin:'auto'}}>
        Max Length 300.</div>
        <DialogContent>
          <TextareaAutosize
            aria-label="minimum height"
            style={{ width: "100%" }}
            minRows={4}
            placeholder="Notes"
            onChange={(e)=>setNotes(e.target.value)}
            maxLength="300"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Discard
          </Button>
          <Button onClick={saveNotes} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
