import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LeftsideMenu from './LeftsideMenu';
import "react-datepicker/dist/react-datepicker.css";
import logodb from "../img/Beats-health-logo.png";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Select from 'react-select';
import Datepickermod from "./datepicker";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { createStyles, Theme } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastError, ToastSuccess } from "../service/toast/Toast";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";


import StaffSelection from './StaffSelection/StaffSelection';

const drawerWidth = 240;

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {},
  })
)(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const [open] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [patientId, setPatientId] = React.useState("");
  const [patientInsuranceId, setPatientInsuranceId] = React.useState("");
  const [defServiceId, setDefServiceId] = React.useState(30);
  const [showControlsUpload, setShowControlsUpload] = React.useState("none");
  const [showControlsManual, setShowControlsManual] = React.useState("block");
  const [documentTypeHistory, setDocumentTypeHistory] = React.useState({
    front:false,
    back:false,
    sec_front:false,
    sec_back:false,
    ter_front:false,
    ter_back:false,
    dl_front:false,
    dl_back:false
  });
  const [gridData, setGridData] = React.useState({payer:[]});
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  var today = new Date();
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  const [patientDetails, setPatientDetails] = React.useState({
    patient_id: "",
    patient_insurance_record_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: today.getFullYear() + "-" + ((today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)) + "-" + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate()) + 'T12:00:00.000Z',
    country_code_label: "US",
    country_code: "",
    contact_number: "",
    email: "",
    dateOfService: today.getFullYear() + "-" + ((today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)) + "-" + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate()) + 'T12:00:00.000Z',
    serviceRequiredFor: "Self",
    payername: "",
    payor_external_id: "",
    subId: "",
    eligibility: "",
    service_type: defServiceId
  });

  //Modal state and handler.
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const patientName = `${patientDetails.first_name} ${patientDetails.middle_name} ${patientDetails.last_name}`;

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  const relationshipOptions = [ { value: 'Self', label: 'Self' },
                                { value: 'Spouse', label: 'Spouse' },
                                { value: 'Child', label: 'Child' },
                                { value: 'Employee', label: 'Employee' },
                                { value: 'Organ Donor', label: 'Organ Donor' },
                                { value: 'Cadaver Donor', label: 'Cadaver Donor' },
                                { value: 'Life Partner', label: 'Life Partner' },
                                { value: 'Other Relationship', label: 'Other Relationship' },
                                { value: 'Other Adult', label: 'Other Adult' },
                              ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      borderBottom: '1px dotted pink',
      padding: 20,
    }),
  }

  const setPatientDetailsdata = (fieldName, val) => {
    ////debugger;
    const data = { ...patientDetails };
    const regExNumberOnly = /^\d{0,10}$/;
    const regExNumberDash = /^(?:\d[-]?){0,10}$/;

    if (fieldName === 'contact_number' && val.length > 12) {
      ToastError("Please enter a valid cell number");
      return;
    } 
    if (fieldName === 'contact_number' && val.length === 10 && regExNumberOnly.test(val)) {
      val  = val.substring(0, 3) + '-' + val.substring(3, 6) + '-' + val.substring(6, 10) ;
    }
    if (fieldName === 'contact_number' && val.length < 10 && val.length > 0 ) {
      if (regExNumberDash.test(val) === false) {
        ToastError("Please enter a valid cell number");
      } 
    }
    data[fieldName] = val;
    if(fieldName === "subId" && val !== "") {
      data["eligibility"] = "Check Pending"
    }
    setPatientDetails(data);
    if(fieldName === 'service_type') {
      setDefServiceId(val);
    }
  };

  const onFileChangeNew = async(docTypeInt, event) => {
    ////debugger;
    if (event.target.files.length === 0) {
      let type = {...documentTypeHistory};  
      if(docTypeInt==="1") {
        type['front'] = false;
      }else if(docTypeInt==="2"){
        type['back'] = false;
      }else if(docTypeInt==="3"){
        type['sec_front'] = false;
      }else if(docTypeInt==="4"){
        type['sec_back'] = false;
      }else if(docTypeInt==="5"){
        type['ter_front'] = false;
      }else if(docTypeInt==="6"){
        type['ter_back'] = false;
      }else if(docTypeInt==="7"){
        type['dl_front'] = false;
      }else{
        type['dl_back'] = false;
      }
      setDocumentTypeHistory(type);
      return;
    }

    let imgPreview = document.getElementById("img1-preview");
    switch (docTypeInt) {
      case "1": 
        imgPreview = document.getElementById("img1-preview");
        break;
      case "2": 
        imgPreview = document.getElementById("img2-preview");
        break;
      case "3": 
        imgPreview = document.getElementById("img3-preview");
        break;
      case "4": 
        imgPreview = document.getElementById("img4-preview");
        break;
      case "5": 
        imgPreview = document.getElementById("img5-preview");
        break;
      case "6": 
        imgPreview = document.getElementById("img6-preview");
        break;
      case "7": 
        imgPreview = document.getElementById("img7-preview");
        break;
      default:
        imgPreview = document.getElementById("img8-preview");
        break;
    }
    const imgfiles = event.target.files[0];
    if (imgfiles) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(imgfiles);
      fileReader.addEventListener("load", function () {
        imgPreview.style.display = "block";
        imgPreview.innerHTML = '<img src="' + this.result + '" />';
      });    
    }
  
    const fileinfonew = {
      filename: event.target.files[0].name,
      fileInfo: event.target.files[0],
    };
    const filedata = [...uploadedFiles];
    filedata.push(fileinfonew);
    var data = new FormData();
    const result = await toBase64(event.target.files[0]).catch(e => Error(e));
    if(result instanceof Error) {
      console.log('Error: ', result.message);
      return;
    }
    let filename = event.target.files[0].name;
    // convertedFile = result.replace(/^data:image\/\w+;base64,/, "")
    let convertedFile = result.split(',')[1];
    data.append("file", convertedFile);
    let fileData = {
      "fileData": convertedFile
    }
    setUploadedFiles([])
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_MANUALOBONARDING_PATIENT_DOCUMENT_UPLOAD_API,
      headers: {
        "Content-Type": "application/json",
        filename: filename,
        patientId: patientId,
        "type-of-side": docTypeInt,
        "type-of-file": event.target.files[0].type,
        Authorization: "Bearer " + sessionStorage.getItem("idToken"),
      },
      data: fileData
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        let type = {...documentTypeHistory};
        if(docTypeInt==="1"){
          type['front'] = true;
          setDocumentTypeHistory(type);
          ToastSuccess("Successfully uploaded frontside image");
        }else if(docTypeInt==="2"){
          type['back'] = true;
          setDocumentTypeHistory(type);
          ToastSuccess("Successfully uploaded backside image");
        }else if(docTypeInt==="3"){
          type['sec_front'] = true;
          setDocumentTypeHistory(type);
          ToastSuccess("Successfully uploaded secondary frontside image");
        }else if(docTypeInt==="4"){
          type['sec_back'] = true;
          setDocumentTypeHistory(type);
          ToastSuccess("Successfully uploaded secondary backside image");
        }else if(docTypeInt==="5"){
          type['ter_front'] = true;
          setDocumentTypeHistory(type);
          ToastSuccess("Successfully uploaded tertiary frontside image");
        }else if(docTypeInt==="6"){
          type['ter_back'] = true;
          setDocumentTypeHistory(type);
          ToastSuccess("Successfully uploaded tertiary backside image");
        }else if(docTypeInt==="7"){
          type['dl_front'] = true;
          setDocumentTypeHistory(type);
          ToastSuccess("Successfully uploaded licence frontside image");
        }else{
          type['dl_back'] = true;
          setDocumentTypeHistory(type);
          ToastSuccess("Successfully uploaded licence backside image");
        }
    })
    .catch(function (error) {
      setLoading(false);
      ToastError("Failed To upload document!");
      console.log(error);
    });
    setUploadedFiles(filedata);
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const attribs = () => {
    return localStorage.getItem("attributes")
      ? JSON.parse(localStorage.getItem("attributes"))
      : [];
  };

  const attribVal = (attribName) => {
    let att = attribs();
    let ky = att.find((x) => x.name === attribName);
    return ky && ky.value ? ky.value : "";
  };

  const fetchData = async() => {
    ////debugger;
    let config = {
      method: "get",
      url: process.env.REACT_APP_BEATS_GET_PAYER_TYPE_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("idToken"),
      },
    };

    let serviceConfig = {
      method: "get",
      url: process.env.REACT_APP_BEATS_LIST_SERVICE_TYPE_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("idToken"),
      },
    };

    setLoading(true);

    try{
      const data = { ...patientDetails };
      let payer   = await axios(config);
      let service = await axios(serviceConfig);
      let optionsData = [...gridData.payer];
      console.log("TypeOfService: ", attribVal("custom:TypeOfService"));
      let defaultServiceTypeId = "30" || attribVal("custom:TypeOfService") ;
      console.log("TypeOfService final is : ", defaultServiceTypeId);

      const defaultServiceTypeIdInt = parseInt(defaultServiceTypeId);
      data['service_type'] = defaultServiceTypeIdInt;
      setPatientDetails(data);
      const defaultServiceTypeName = "Service Type (" + service.data.find(x => x.service_type_Id === defaultServiceTypeIdInt).service_name + ")";
      let options = [{ value: defaultServiceTypeIdInt, label: defaultServiceTypeName }];
      console.log("Default service type set to ", defaultServiceTypeIdInt, defaultServiceTypeName);

      for (var i = 0; i < payer.data.length; i++) {
        let obj = {
          value: payer.data[i].payor_external_id,
          label: payer.data[i].payor_external_id + " - " + payer.data[i].payor_name,
        };
        optionsData.push(obj);
      }
      for (var j = 0; j < service.data.length; j++) {
        let objval = {
          value: service.data[j].service_type_Id,
          label: service.data[j].service_name,
        };
        if(service.data[j].service_type_Id !== defaultServiceTypeIdInt) {
          options.push(objval);
        }
      }
      
      setGridData({payer:optionsData});
      setServiceOptions(options);
      setDefServiceId(defaultServiceTypeIdInt);
      setLoading(false);

    }catch(error){
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateOnBoarding = () => {
    ////debugger;
    let data = {...patientDetails};

    //Validations - TO BE COMPLETED
    var isError = false;
    var regExContactNumber = /^\d{3}-\d{3}-\d{4}$/;

    if (data.first_name === '') {
      ToastError("Enter patient's first name");
      isError = true;
    }
    if (data.last_name === '') {
      ToastError("Enter patient's last name");
      isError = true;
    }
   /* if (data.contact_number === '' || !regExContactNumber.test(data.contact_number)) {
      ToastError("Enter contact number");
      isError = true;
    }
    if (data.email === '') {
      ToastError("Enter valid email");
      isError = true;
    }
    if (data.dob === today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate()) {
      ToastError("Enter your date of birth");
      isError = true;
    } */
    if(isError) {
      return;
    }
    if(data.service_type!=="" && data.service_type!==null){
      data['service_type'] = parseInt(data['service_type']);
    }
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_MANUALOBONARDING_PATIENT_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("idToken"),
      },
      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        ////debugger;
        setPatientId(response.data.patientId);
        setPatientInsuranceId(response.data.patientInsuranceId);
        let patientdata = {...patientDetails};
        patientdata["patient_id"] = response.data.patientId;
        patientdata["patient_insurance_record_id"] = response.data.patientInsuranceId;
        setPatientDetails(patientdata);
        ToastSuccess("Patient successfully registered. Please provide insurance details");
        setLoading(false);
        executeScroll();
    })
    .catch(function (error) {
      setLoading(false);
      setPatientDetails({
        patient_id: "",
        patient_insurance_record_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        dob: today.getFullYear() + "-" + ((today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)) + "-" + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate()) + 'T12:00:00.000Z',
        country_code: "",
        contact_number: "",
        email: "",
        dateOfService: today.getFullYear() + "-" + ((today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)) + "-" + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate()) + 'T12:00:00.000Z',
        serviceRequiredFor: "Self",
        payername: "",
        subId: "",
        eligibility:"",
        service_type: defServiceId
      });
      ToastError("Failed to register patient");
      console.log(error);
    });
  };

  const completeOnBoarding = () => {

    // If the Patient is already created but they want to upload insurance image, do nothing and exit
    if(showControlsUpload === "block") {
      history.push("/MonitoringDashboard");
      return;
    }

    //If the Patient detailed are being entered manually, revalidate all values before deleting/creating the patient again
    let data = {...patientDetails};

    //Validations - TO BE COMPLETED
    var isError = false;
    var regExContactNumber = /^\d{3}-\d{3}-\d{4}$/;

    if (data.first_name === '') {
      ToastError("Enter patient's first name");
      isError = true;
    }
    if (data.last_name === '') {
      ToastError("Enter patient's last name");
      isError = true;
    }
    /*if (data.contact_number === '' || !regExContactNumber.test(data.contact_number)) {
      ToastError("Enter contact number");
      isError = true;
    }
    if (data.email === '') {
      ToastError("Enter valid email");
      isError = true;
    }
    if (data.dob === '' || data.dob === today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate()) {
      ToastError("Enter valid email");
      isError = true;
    }*/

    if(isError) {
      return;
    }
    if(data.service_type!=="" && data.service_type!==null){
      data['service_type'] = parseInt(data['service_type']);
    }
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_UPDATE_PATIENT_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("idToken"),
      },
      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        ////debugger;
        ToastSuccess("Patient successfully registered");
        setLoading(false);
        history.push("/MonitoringDashboard");
      })
      .catch(function (error) {
        setLoading(false);
        ToastError("Failed to register patient");
        console.log(error);
      });
  };

  const showUpload=(patientId !== "" ? "block" : "none");
  const showContinue=(patientId !== "" ? "none" : "block");

  const manageShowControls = (checkValue) => {
    if(checkValue === "Upload") {
      setShowControlsUpload("block");
      setShowControlsManual("none");
      setPatientDetailsdata( "subId", "" );
      setPatientDetailsdata( "eligibility", "" );
    }
    if(checkValue === "Manually") {
      setShowControlsUpload("none");
      setShowControlsManual("block");
    }
  }

  const staffs = [
    { id: 1, name: 'Dr.Rajesh Sekar' },
    { id: 2, name: 'Dr.Thomas Moran' },
    { id: 3, name: 'Dr.Steven LoBue' },
    { id: 4, name: 'Dr.Michelle Pfeiffer' }
  ];

  return (
      <div className={classes.root}>
        <CssBaseline />
        <ToastContainer />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <img src={logodb} className="dblogo" alt="Beats Logo" />
          </div>

          <LeftsideMenu/>

        </Drawer>
        <main id="maindiv" className={classes.content}>
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <h5 className="btitle">Manual Registration</h5>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className="pad20 txt-center bx-shadow dbbox">

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} className="pd0">
                      <p className="txt-left linkprim">Patient Details</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className="pd0">
                      <BootstrapInput
                        className="primary-input mb20 width100p"
                        placeholder="Patient First Name"
                        onChange={(e) => {
                          setPatientDetailsdata("first_name", e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className="pd0">
                      <BootstrapInput
                        className="primary-input mb20 width100p"
                        placeholder="Patient Middle Name (Optional)"
                        onChange={(e) => {
                          setPatientDetailsdata("middle_name", e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pd0">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} className="pd0">
                          <BootstrapInput
                            onChange={(e) => {
                              setPatientDetailsdata("last_name", e.target.value);
                            }}
                            className="primary-input mb20 width100p"
                            placeholder="Patient Last Name"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className="pt0">
                      <Datepickermod
                        label={"Date of Birth (mm/dd/yyyy)"}
                        value={(patientDetails.dob.length === 20 || patientDetails.dob.length === 24) ? patientDetails.dob.split("T")[0]+"T12:00:00Z" : patientDetails.dob }
                        maxDate={Date.now()}
                        dateChanged={(val) => {
                          setPatientDetailsdata("dob", val);
                        }}
                      />
                    </Grid>
                  </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <p className="txt-left linkprim">Service Details</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} className="datepicker-cut">
                    <Datepickermod
                      label={"Date of Service (mm/dd/yyyy)"}
                      value={(patientDetails.dateOfService.length === 20 || patientDetails.dateOfService.length === 24) ? patientDetails.dateOfService.split("T")[0] + "T12:00:00Z" : patientDetails.dateOfService}
                      minDate={Date.now()}
                      dateChanged={(val) => {
                        setPatientDetailsdata("dateOfService", val);
                      }}
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} >
                    <Button
                      variant="contained"
                      className="btn-primary"
                      onClick={handleOpen}
                      style={{"display":"None" }}
                    >
                      Assign Clinician/Staff
                    </Button>
                    {modalOpen &&
                      <StaffSelection
                        open={modalOpen}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                        staffs={staffs} 
                        patientName = {patientName}
                        serviceDate={patientDetails.dateOfService}/>
                    }
                  </Grid>
                </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Grid item xs={12} sm={12} md={12} className="txt-left form-radio" >
                         <Select className="selectbox1"
                          onChange = {(selectedOption) => {
                            setPatientDetailsdata("serviceRequiredFor", selectedOption.value);
                            console.log(`Option selected:`, selectedOption);
                          }}
                          styles={customStyles}
                          options={relationshipOptions} 
                          defaultValue={{ value: 'self', label: 'Relationship to Subscriber (Self)' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Grid item xs={12} sm={12} md={12}>
                      <Select className="selectbox1"
                          onChange = {(selectedOption) => {
                            setPatientDetailsdata("service_type", selectedOption.value);
                            console.log(`Option selected:`, selectedOption);
                          }}
                          options={serviceOptions} 
                          styles={customStyles}
                          value = {
                            serviceOptions.filter(option => 
                              option.value === defServiceId)
                            }
                        />
                      </Grid>
                    </Grid>  
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} className="pd0">
                      <p className="txt-left linkprim">Contact Details</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className="pd0">
                      <BootstrapInput
                        className="primary-input mb20 width100p"
                        placeholder="Cell Number"
                        value={patientDetails.contact_number}
                        onChange={(e) => {
                          setPatientDetailsdata("contact_number", e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className="pd0">
                      <BootstrapInput
                        className="primary-input mb20 width100p"
                        placeholder="Email"
                        value={patientDetails.email}
                        onChange={(e) => {
                          setPatientDetailsdata("email", e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="txt-left form-radio"
                      >
                      <Grid container className="pb30 pt30">
                    <Grid item xs={12} sm={12} md={12} style={{"display":showContinue }}>
                      <a href="/Dashboard" className="btn-secondary mr30">
                        Cancel
                      </a>
                        <Button
                          disabled={patientId === "" ? false : true}
                          variant="contained"
                          className="btn-primary"
                          onClick={initiateOnBoarding}
                        >
                          Continue
                        </Button>
                      </Grid>
                    </Grid>
                    <FormControl component="fieldset" style={{"display":showUpload }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <p className="txt-left linkprim mb10 mt20">Insurance Details</p>
                        </Grid>
                          <RadioGroup aria-label="gender" name="gender1">
                            <FormControlLabel
                              value="Upload"
                              control={<Radio  
                                checked={showControlsUpload === "block"}
                                onChange={(e) => {
                                  manageShowControls(
                                    e.target.value
                                  );
                                }}      
                              />}
                              label="Upload front and back of patient's insurance card(s)"
                            />
                            <div style={{"display":showControlsUpload }}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                  <Box component="div" className="uploadfile">
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <Box component="div" className="filedone">
                                          <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                              <DescriptionIcon />
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex!important", alignItems: "center!important", }} >
                                              <div>
                                                <p>Front side</p>
                                              </div>
                                            </Grid>
                                            <Grid item xs={6} className="txt-right">
                                            {
                                              documentTypeHistory.front?
                                              <CheckCircleIcon style={{ color: "green" }} />:<HighlightOffIcon style={{ color: "red" }} />
                                            } 
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} className="pd20">
                                              <input type="file" id="upload1" accept=".jpg,.jpeg.,.gif,.png" hidden onChange={(e) => {
                                                onFileChangeNew("1", e);
                                              }}
                                              />
                                              <label for="upload1" class="custom-file-input"></label>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} className="pd20">
                                              <div id="img1-preview" class="cust-select"></div>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="pt30">
                                      <Grid item xs={12} sm={12} md={12}>
                                        <Box component="div" className="filedone">
                                          <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                              <DescriptionIcon />
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex!important", alignItems: "center!important", }} >
                                              <div>
                                                <p>Back side</p>
                                              </div>
                                            </Grid>
                                            <Grid item xs={6} className="txt-right">
                                            {
                                              documentTypeHistory.back?
                                              <CheckCircleIcon style={{ color: "green" }} />:<HighlightOffIcon style={{ color: "red" }} />
                                            } 
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} className="pd20">
                                              <input type="file" id="upload2" accept=".jpg,.jpeg.,.gif,.png" hidden onChange={(e) => {
                                                onFileChangeNew("2", e);
                                              }}
                                              />
                                              <label for="upload2" class="custom-file-input"></label>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} className="pd20">
                                              <div id="img2-preview" class="cust-select"></div>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                           </div>
                            <div style={{"display":showControlsUpload }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <Box component="div" className="uploadfile">
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                          <Box component="div" className="filedone">
                                            <Grid container spacing={1}>
                                              <Grid item xs={2}>
                                                <DescriptionIcon />
                                              </Grid>
                                              <Grid item xs={2} style={{ display: "flex!important", alignItems: "center!important", }} >
                                                <div>
                                                  <p>Secondary Front side</p>
                                                </div>
                                              </Grid>
                                              <Grid item xs={6} className="txt-right">
                                              {
                                                documentTypeHistory.sec_front?
                                                <CheckCircleIcon style={{ color: "green" }} />:<HighlightOffIcon style={{ color: "red" }} />
                                              } 
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <input type="file" id="upload3" accept=".jpg,.jpeg.,.gif,.png" hidden onChange={(e) => {
                                                  onFileChangeNew("3", e);
                                                }}
                                                />
                                                <label for="upload3" class="custom-file-input"></label>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <div id="img3-preview" class="cust-select"></div>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={3} className="pt30">
                                        <Grid item xs={12} sm={12} md={12}>
                                          <Box component="div" className="filedone">
                                            <Grid container spacing={1}>
                                              <Grid item xs={2}>
                                                <DescriptionIcon />
                                              </Grid>
                                              <Grid item xs={2} style={{ display: "flex!important", alignItems: "center!important", }} >
                                                <div>
                                                  <p>Secondary Back side</p>
                                                </div>
                                              </Grid>
                                              <Grid item xs={6} className="txt-right">
                                              {
                                                documentTypeHistory.sec_back?
                                                <CheckCircleIcon style={{ color: "green" }} />:<HighlightOffIcon style={{ color: "red" }} />
                                              } 
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <input type="file" id="upload4" accept=".jpg,.jpeg.,.gif,.png" hidden onChange={(e) => {
                                                  onFileChangeNew("4", e);
                                                }}
                                                />
                                                <label for="upload4" class="custom-file-input"></label>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <div id="img4-preview" class="cust-select"></div>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                            </div>
                            <div style={{"display":showControlsUpload }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <Box component="div" className="uploadfile">
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                          <Box component="div" className="filedone">
                                            <Grid container spacing={1}>
                                              <Grid item xs={2}>
                                                <DescriptionIcon />
                                              </Grid>
                                              <Grid item xs={2} style={{ display: "flex!important", alignItems: "center!important", }} >
                                                <div>
                                                  <p>Tertiary Front side</p>
                                                </div>
                                              </Grid>
                                              <Grid item xs={6} className="txt-right">
                                              {
                                                documentTypeHistory.ter_front?
                                                <CheckCircleIcon style={{ color: "green" }} />:<HighlightOffIcon style={{ color: "red" }} />
                                              } 
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <input type="file" id="upload5" accept=".jpg,.jpeg.,.gif,.png" hidden onChange={(e) => {
                                                  onFileChangeNew("5", e);
                                                }}
                                                />
                                                <label for="upload5" class="custom-file-input"></label>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <div id="img5-preview" class="cust-select"></div>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={3} className="pt30">
                                        <Grid item xs={12} sm={12} md={12}>
                                          <Box component="div" className="filedone">
                                            <Grid container spacing={1}>
                                              <Grid item xs={2}>
                                                <DescriptionIcon />
                                              </Grid>
                                              <Grid item xs={2} style={{ display: "flex!important", alignItems: "center!important", }} >
                                                <div>
                                                  <p>Tertiary Back side</p>
                                                </div>
                                              </Grid>
                                              <Grid item xs={6} className="txt-right">
                                              {
                                                documentTypeHistory.ter_back?
                                                <CheckCircleIcon style={{ color: "green" }} />:<HighlightOffIcon style={{ color: "red" }} />
                                              } 
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <input type="file" id="upload6" accept=".jpg,.jpeg.,.gif,.png" hidden onChange={(e) => {
                                                  onFileChangeNew("6", e);
                                                }}
                                                />
                                                <label for="upload6" class="custom-file-input"></label>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <div id="img6-preview" class="cust-select"></div>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                            </div>
                            <div style={{"display":showControlsUpload }}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <Box component="div" className="uploadfile">
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                          <Box component="div" className="filedone">
                                            <Grid container spacing={1}>
                                              <Grid item xs={2}>
                                                <DescriptionIcon />
                                              </Grid>
                                              <Grid item xs={2} style={{ display: "flex!important", alignItems: "center!important", }} >
                                                <div>
                                                  <p>Driver Licence Front side</p>
                                                </div>
                                              </Grid>
                                              <Grid item xs={6} className="txt-right">
                                              {
                                                documentTypeHistory.dl_front?
                                                <CheckCircleIcon style={{ color: "green" }} />:<HighlightOffIcon style={{ color: "red" }} />
                                              } 
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <input type="file" id="upload7" accept=".jpg,.jpeg.,.gif,.png" hidden onChange={(e) => {
                                                  onFileChangeNew("7", e);
                                                }}
                                                />
                                                <label for="upload7" class="custom-file-input"></label>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <div id="img7-preview" class="cust-select"></div>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={3} className="pt30">
                                        <Grid item xs={12} sm={12} md={12}>
                                          <Box component="div" className="filedone">
                                            <Grid container spacing={1}>
                                              <Grid item xs={2}>
                                                <DescriptionIcon />
                                              </Grid>
                                              <Grid item xs={2} style={{ display: "flex!important", alignItems: "center!important", }} >
                                                <div>
                                                  <p>Driver Licence Back side</p>
                                                </div>
                                              </Grid>
                                              <Grid item xs={6} className="txt-right">
                                              {
                                                documentTypeHistory.dl_back?
                                                <CheckCircleIcon style={{ color: "green" }} />:<HighlightOffIcon style={{ color: "red" }} />
                                              } 
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <input type="file" id="upload8" accept=".jpg,.jpeg.,.gif,.png" hidden onChange={(e) => {
                                                  onFileChangeNew("8", e);
                                                }}
                                                />
                                                <label for="upload8" class="custom-file-input"></label>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={6} className="pd20">
                                                <div id="img8-preview" class="cust-select"></div>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                            </div>
                            <FormControlLabel
                              value="Manually"
                              control={<Radio 
                                checked={showControlsManual === "block"}
                                onChange={(e) => {
                                  manageShowControls(
                                    e.target.value
                                  );
                                }}      
                              />}
                              label="Manually enter patient's insurance card details"
                            />

                            <div style={{"display":showControlsManual }}>
                              <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={4}>
                                  <Select className="selectbox1"
                                    onChange = {(selectedOption) => {
                                      setPatientDetailsdata("payor_external_id", selectedOption.value);
                                    }}
                                    styles={customStyles}
                                    options={gridData.payer}
                                    value={gridData.payer.filter(option => 
                                      option.value === (patientDetails["payor_external_id"] ))}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <BootstrapInput
                                    className="primary-input mb20 width100p"
                                    placeholder="Subscriber ID"
                                    onChange={(e) => {
                                      setPatientDetailsdata(
                                        "subId",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </RadioGroup>
                          <Grid container className="pb30 pt30">
                          <Grid item xs={12} sm={12} md={12}>
                            <a href="/Dashboard" className="btn-secondary mr30">
                              Cancel
                            </a>
                              <Button
                                variant="contained"
                                className="btn-primary"
                                onClick={completeOnBoarding}
                              >
                                Complete Registration
                              </Button>
                            </Grid>
                          </Grid>
                        </FormControl>
                        
                      </Grid>
                      
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
          <div ref={myRef}></div>
        </main>
      </div>
  );
}
